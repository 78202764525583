import React, { useState, useEffect } from 'react';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBCard,
  MDBFile
} from 'mdb-react-ui-kit';
import { Form, Row, Col } from 'react-bootstrap';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hook/useAxiosPrivate";
import { useFormik, Field, ErrorMessage, FormikProvider } from "formik";
import * as Yup from "yup";
import { useAuth } from '../../../context/AuthContext';
function AddAdmin() {

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const taskId = searchParams.get('taskId');
  const axios = useAxiosPrivate();
  const { auth } = useAuth();
  const { restaurantDetail } = auth;


  const [updateData, setUpdateData] = useState([]);
  const isUpdateForm = Object.keys(updateData).length > 0;
  const [categories, setCategories] = useState([]);
  const [roles, setRoles] = useState([]);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };

  useEffect(() => {
    if (taskId) {
      console.log("ParamId", taskId);
      axios.get(`/users/${taskId}`)
        .then(response => {

          const data = response.data;
          console.log(data);
          setUpdateData(data);
          const initialValues = {
            roleId:data.roleId || '',
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            mobile: data.mobile || '',
            email: data.email || '',
            passwordHash: data.passwordHash || '',
            status: data.status || "",
            package_id: data.package_id || null,
            termsFile: data.terms_conditions_file_path || '',
            max_employees: data.max_employees || null,
            national_identity_number: data.national_identity_number || '',
            area: data.area || '',
            city: data.city || '',
          };
          formik.setValues(initialValues);
        })
        .catch(error => {
          console.error('Error fetching record:', error);
        });
    }
  }, [taskId]);

  useEffect(() => {
    console.log("Data to be updated:", updateData);

  }, [updateData]);

  useEffect(() => {
    axios.get(`/role`)
      .then(response => {

        const data = response.data;
        console.log("roles", data);
        setRoles(data.data);

      })
      .catch(error => console.error('Error fetching categories:', error));

  }, []);
  useEffect(() => {
    axios.get(`/admin_package`)
      .then(response => {

        const data = response.data;
        console.log("packages", data);
        setCategories(data.data);

      })
      .catch(error => console.error('Error fetching categories:', error));

  }, []);

  useEffect(() => {
    console.log("Cateories:", categories);
  }, [categories]);

  useEffect(() => {
    console.log("Roles:", roles);
  }, [roles]);

  const addRecord = async (values) => {

    try {
      console.log("Making a POST request to fooditems with data:", values);

      const response = await axios.post('/users', values, config);

      console.log("Response status:", response.status);
      console.log("Response data:", response.data);
      alert("Added Successfully!");
      setTimeout(function () {

        // handleButtonClick();
      }, 3000);
    } catch (error) {
      console.error("Error while making the POST request:", error.message);
      alert("Error Adding Record");
    }
  };

  const updateRecord = async (values) => {

    try {
      console.log(`Making a PUT request to fooditems with data:`, values);

      const response = await axios.put(`/users${updateData.id}`, values, config);

      console.log("Response status:", response.status);
      console.log("Response data:", response.data);
      alert("Updated Successfully!");
      setTimeout(function () {

        //  handleButtonUpdate();
      }, 3000);
    } catch (error) {

      alert("Error Updating Record");
    }
  };

  const formik = useFormik({
    initialValues: {
      roleId: '',
      firstName: '',
      lastName: "",
      mobile: "",
      email: "",
      passwordHash: "",
      status: "",
      package_id: null,
      termsFile: '',
      max_employees: null,
      national_identity_number: "",
      area: '',
      city: '',

    },

    validationSchema: Yup.object({
      firstName: Yup.string().required('This field is required'),
      lastName: Yup.string().required('This field is required'),
      mobile: Yup.string().required('This field is required'),
      email: Yup.string().required('This field is required'),
      passwordHash: Yup.string().required('This field is required'),
      status: Yup.string().required('This field is required'),
      package_id: Yup.number().required('This field is required'),
      roleId: Yup.number().required('This field is required'),
      max_employees: Yup.number().required('This field is required'),
      national_identity_number: Yup.string().required('This field is required'),
      area: Yup.string().required('This field is required'),
      city: Yup.string().required('This field is required'),

    }),
    onSubmit: (values) => {
      // Handle form submission logic, e.g., send data to the server
      console.log("in sunmit");
      isUpdateForm ? updateRecord(values) : addRecord(values);

      navigate('/kitchen-admins');

    },
  });
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <FormikProvider value={formik}>
          <button type="button" className="btn btn-primary mt-3 ms-5" data-mdb-ripple-init="true">
            <Link to="/kitchen-admins" className="text-white text-decoration-none">User List</Link>
          </button>
          < MDBCard className='p-5 mt-3'>
            <form onSubmit={formik.handleSubmit}>
            <MDBRow className='mb-4'>
                <MDBCol>
                <Form.Group controlId="roleId">
                    <label className="form-label" htmlFor="roleId">Role</label>
                    <Field
                      as="select"
                      className="browser-default custom-select"
                      id="roleId"
                      name="roleId"
                      value={formik.values.roleId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.roleId && formik.errors.roleId}
                    >
                      <option value="" disabled selected>Select User Role</option>
                      {roles.map(category => (
                        <option key={category.id} value={category.id}>{category.title}</option>
                      ))}
                    </Field>
                    <small><ErrorMessage name="package_id" component="div" className="text-danger" /></small>
                  </Form.Group>
                </MDBCol>
              </MDBRow>
              <MDBRow className='mb-4'>
                <MDBCol>
                  <Form.Group controlId="firstName">
                    <label className="form-label" htmlFor="firstName">First Name</label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.firstName && formik.errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">{formik.errors.firstName}</Form.Control.Feedback>
                  </Form.Group>
                </MDBCol>
                <MDBCol>
                  <Form.Group controlId="lastName">
                    <label className="form-label" htmlFor="lastName">Last Name</label>
                    <Form.Control
                      type="text"

                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.lastName && formik.errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">{formik.errors.lastName}</Form.Control.Feedback>
                  </Form.Group>
                </MDBCol>
              </MDBRow>
              <MDBRow className='mb-4'>
                <MDBCol>
                  <Form.Group controlId="mobile">
                    <label className="form-label" htmlFor="mobile">Mobile Number</label>
                    <Form.Control
                      type="text"

                      name="mobile"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.mobile && formik.errors.mobile}
                    />
                    <Form.Control.Feedback type="invalid">{formik.errors.mobile}</Form.Control.Feedback>
                  </Form.Group>
                </MDBCol>
                <MDBCol>
                  <Form.Group controlId="email">
                    <label className="form-label" htmlFor="email">Email</label>
                    <Form.Control
                      type="email"

                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.email && formik.errors.email}
                    />
                    <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                  </Form.Group>
                </MDBCol>
              </MDBRow>
              <MDBRow className='mb-4'>
                <MDBCol>
                  <Form.Group controlId="max_employees">
                    <label className="form-label" htmlFor="max_employees">Maximum Employees</label>
                    <Form.Control
                      type="number"

                      name="max_employees"
                      value={formik.values.max_employees}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.max_employees && formik.errors.max_employees}
                    />
                    <Form.Control.Feedback type="invalid">{formik.errors.max_employees}</Form.Control.Feedback>
                  </Form.Group>
                </MDBCol>
                <MDBCol>
                  <Form.Group controlId="national_identity_number">
                    <label className="form-label" htmlFor="national_identity_number">National Identity Number</label>
                    <Form.Control
                      type="text"

                      name="national_identity_number"
                      value={formik.values.national_identity_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.national_identity_number && formik.errors.national_identity_number}
                    />
                    <Form.Control.Feedback type="invalid">{formik.errors.national_identity_number}</Form.Control.Feedback>
                  </Form.Group>
                </MDBCol>
              </MDBRow>
              <MDBRow className='mb-4'>
                <MDBCol>
                  <Form.Group controlId="area">
                    <label className="form-label" htmlFor="area">Area</label>
                    <Form.Control
                      type="text"

                      name="area"
                      value={formik.values.area}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.area && formik.errors.area}
                    />
                    <Form.Control.Feedback type="invalid">{formik.errors.area}</Form.Control.Feedback>
                  </Form.Group>
                </MDBCol>
                <MDBCol>
                  <Form.Group controlId="city">
                    <label className="form-label" htmlFor="city">City</label>
                    <Form.Control
                      type="text"
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.city && formik.errors.city}
                    />
                    <Form.Control.Feedback type="invalid">{formik.errors.city}</Form.Control.Feedback>
                  </Form.Group>
                </MDBCol>
              </MDBRow>
              <MDBRow className='mb-4'>
                <MDBCol>
                  <Form.Group controlId="price">

                    <label className="form-label" htmlFor="passwordHash">Password</label>
                    <input
                      type="password"

                      name="passwordHash"
                      value={formik.values.passwordHash}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-control"
                    // You can add more props as needed
                    />
                    <small><ErrorMessage name="passwordHash" component="div" className="text-danger" /></small>
                  </Form.Group>
                </MDBCol>
                <MDBCol>
                  <Form.Group controlId="status">
                    <label className="form-label" htmlFor="status">Status</label>
                    <Field
                      as="select"
                      className="browser-default custom-select"
                      id="status"
                      name="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.status && formik.errors.status}
                    >
                      <option value="" disabled selected>Select Status</option>

                      <option value="active">Active</option>
                      <option value="suspended">Suspended</option>

                    </Field>
                    <small><ErrorMessage name="status" component="div" className="text-danger" /></small>
                  </Form.Group>
                </MDBCol>
              </MDBRow>
              <MDBRow className='mb-4'>
                <MDBCol>
                  <Form.Group controlId="package_id">
                    <label className="form-label" htmlFor="package_id">Package</label>
                    <Field
                      as="select"
                      className="browser-default custom-select"
                      id="package_id"
                      name="package_id"
                      value={formik.values.package_id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.package_id && formik.errors.package_id}
                    >
                      <option value="" disabled selected>Select Package</option>
                      {categories.map(category => (
                        <option key={category.id} value={category.id}>{category.name}</option>
                      ))}
                    </Field>
                    <small><ErrorMessage name="package_id" component="div" className="text-danger" /></small>
                  </Form.Group>
                </MDBCol>
                <MDBCol>
                  <Form.Group controlId="termsFile">
                    <label className="form-label" htmlFor="termsFile">Terms & Conditions Document</label>
                    <input
                      type="file"
                      name="termsFile"

                      onChange={(event) => formik.setFieldValue('termsFile', event.currentTarget.files[0])}
                      className={` inputclass form-control ${formik.touched.termsFile && formik.errors.termsFile ? 'is-invalid' : ''
                        }`}
                    />
                    <div className="invalid-feedback">{formik.errors.termsFile}</div>
                  </Form.Group>
                </MDBCol>
              </MDBRow>


              <button className='mb-4 mt-5 btn btn-primary w-100' type='submit' >
                {isUpdateForm ? 'Update' : 'Add'}
              </button>
            </form>
          </MDBCard>
        </FormikProvider>

      </DashboardLayout>
    </>
  );
}
export default AddAdmin;